import './css/education.css'
import React, {useContext} from 'react'
import { MyContext } from '../context'
import text from '../data/text'

function Education() {

    const context = useContext(MyContext)

    return (
        <>
            <div className="top-container">
            <div className="headline">{text.nav.education[context.state.lang]}</div>
            <div className="headline"></div>
            </div>

            <div className="bottom-container">
            </div>
        </>
    )
}

export default Education