import './css/header.css'
import { NavLink } from "react-router-dom"
import React, { useContext } from "react"
import { MyContext } from '../context'
import text from '../data/text'

function Header() {

const context = useContext(MyContext)

    return (
        <div className="centered-content">
            <div className="navbar">
            <NavLink to="poetry" className="navlink">{text.nav.poetry[context.state.lang]}</NavLink>
            <NavLink to="webdesign" className="navlink">Web design</NavLink>

            <NavLink to="education" className="navlink">{text.nav.education[context.state.lang]}</NavLink>

            <NavLink to="contact" className="navlink">Contact</NavLink>


                <div className="language">
                    <div onClick={() => { context.langHandler("fr") }}>FR</div>
                    <div onClick={() => { context.langHandler("en") }}>EN</div>
                </div>
            </div>
        </div>
    )
}

export default Header