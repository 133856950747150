import './css/webdesign.css'

function Webdesign() {
    return (
        <>
            <div className="top-container">
            <div className="headline">web design</div>
            <div className="headline"></div>
            </div>

            <div className="bottom-container">
            </div>
        </>
    )
}

export default Webdesign