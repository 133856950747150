
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './assets/css/styles.css'
import './assets/fonts/fonts.css'
import Header from './components/Header';
import Home from './components/Home';
import Webdesign from './components/Webdesign';
import Poetry from './components/Poetry';
import Education from "./components/education";

function App() {
  return (
    <div className="app-container">
      <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="webdesign" element={<Webdesign />} />
        <Route path="poetry" element={<Poetry />} />
        <Route path="education" element={<Education />} />
      </Routes>
    </Router>
    </div>
  );
}

export default App;
