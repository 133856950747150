import './css/poetry.css'
import floraisons from '../assets/img/floraisons.png'
import fluences from '../assets/img/fluences.png'
import flaches from '../assets/img/flaches.png'
import React, {useContext} from 'react'
import { MyContext } from '../context'
import text from '../data/text'

function Poetry() {

    const context = useContext(MyContext)
    return (
        <>
            <div className="top-container">
            <div className="headline">{text.nav.poetry[context.state.lang]}</div>
            <div className="headline"></div>
            </div>

            <div className="bottom-container">
                <div className="grid">
                    <div className="book-img-container ">
                        <img
                            src={floraisons}
                            alt=""
                            className="book-img"
                        />
                    </div>
                    <div className="book-img-container ">
                        <img
                            src={fluences}
                            alt=""
                            className="book-img"
                        />
                    </div>
                    <div className="book-img-container ">
                        <img
                            src={flaches}
                            alt=""
                            className="book-img"
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Poetry