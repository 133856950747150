import React, { Component } from "react";

const MyContext = React.createContext()

class MyProvider extends Component {
    state = {
        lang: "en"
    }
    langHandler = (selectedLanguage) => {
        this.setState({ lang: selectedLanguage })
    }
    render() {
        return (
            <MyContext.Provider
                value={{
                    state: this.state,
                    langHandler: this.langHandler
                }}
            >
                {this.props.children}
            </MyContext.Provider>
        )
    }
}
export {
    MyContext,
    MyProvider
}