const text = {
    nav: {
        poetry: {
            fr: "poésie",
            en: "poetry"
        },
        education: {
            fr: "pédagogie",
            en: "education"
        }
    }
}

export default text