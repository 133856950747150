import './css/home.css'

function Home() {
    return (
        <>
            <div className="headline">aurelio</div>
            <div className="headline">maurici</div>
        </>
    )
}

export default Home